import React, { Component, Fragment } from "react"
import Obfuscate from "react-obfuscate"
import Recaptcha from "react-google-recaptcha"
import { sendEmail } from "../functions/apiLambda"

import Head from "../components/head"
import Layout from "../components/layout"
import Section from "../components/section"

const PageTitle = "Me contacter"

const siteAuthor = "Gus LYON"
const siteName = "GusLyon.fr"
const siteEmail = "contact@guslyon.fr"

export default class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submit: null,
      isLoading: false
    }
  }

  componentDidMount() {
    if (new RegExp("localhost").test(window.location.origin)) {
      // Ignore reCaptcha locally
      this.setState({ recaptcha: "localhost" })
    }
  }

  handleChange = e => {
    const html = e.target.value.replace(/\r?\n/g, "<br />")
    this.setState({ [e.target.name]: html })
  }

  handleRecaptcha = value => {
    this.setState({ recaptcha: value })
  }

  handleSubmit = e => {
    e.preventDefault() // prevent submit form

    this.setState({ isLoading: true })

    this.setState({ submit: null }) // reset previous submit error

    // Send email
    let mailFrom = `"${this.state.name} (via ${siteName})" <${siteEmail}>` // verified AWS email adress
    let mailReplyTo = `"${this.state.name}" <${this.state.email}>`
    let mailTo = `"${siteAuthor} (${siteName})" <${siteEmail}>`
    let mailSubject = `Message envoyé depuis ${siteName}`
    let mailBody = `
      <html>
        <head>
        <title>${mailSubject}</title>
        </head>
        <body>
          <p>
            ${this.state.message}
          </p>
          <p>
          --
          </p>
          <p><em>Ceci est un message automatique envoyé via ${siteName}</em></p>
        </body>
      </html>
    `
    sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody)
      .then(response => {
        // console.log("response : ", response)
        if (response.status === 200) {
          // SUCCESS
          let mailFrom = `"${siteName}" <${siteEmail}>` // verified AWS email adress
          let mailReplyTo = `"${siteAuthor} (${siteName})" <${siteEmail}>`
          let mailTo = `"${this.state.name}" <${this.state.email}>`
          let mailSubject = `Message envoyé à ${siteAuthor} (${siteName})`
          let mailBody = `
            <html>
              <head>
              <title>${mailSubject}</title>
              </head>
              <body>
                <p>${this.state.name},</p>
                <p>Votre message a bien été envoyé à ${siteAuthor} : ${siteEmail}</p>
                <p>&nbsp;</p>
                <p>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</p>
                <p><strong>VOTRE MESSAGE</strong></p>
                <p>
                  ${this.state.message}
                </p>
                <p>
                --
                </p>
                <p><em>Ceci est un message automatique envoyé via ${siteName}</em></p>
              </body>
            </html>
          `
          sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody)
            .then(response => {
              // console.log("response : ", response)
              if (response.status === 200) {
                // SUCCESS
                this.setState({ isLoading: false, submit: "success" })
              } else {
                // ERROR
                this.setState({ isLoading: false, submit: "error" })
              }
              return response.json()
            })
            .then(data => {
              console.log("sendEmail to user : ", data)
            })
        } else {
          // ERROR
          this.setState({ isLoading: false, submit: "error" })
        }
        return response.json()
      })
      .then(data => {
        console.log("sendEmail to admin : ", data)
      })
    // END Send email
  }

  render() {
    return (
      <div>
        <Head PageTitle={PageTitle} PageSlug="/contact/" />

        <Layout>
          <Section Style="secondary uk-light uk-text-center">
            <h3>{PageTitle}</h3>
            <div>
              Vous pouvez me joindre par téléphone au <Obfuscate tel="06 80 82 97 62" /> ou bien par mail grâce au formulaire ci-dessous.
            </div>
          </Section>

          <Section Style="muted">
            {this.state.submit !== "success" && (
              <form
                id="contact-form"
                className="uk-form-horizontal uk-margin-large uk-margin-remove-bottom"
                name="contact-form"
                method="post"
                onSubmit={this.handleSubmit}
              >
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="name">
                    Nom
                  </label>
                  <div className="uk-form-controls">
                    <input className="uk-input" id="name" type="text" placeholder="Votre nom..." name="name" onChange={this.handleChange} required />
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="email">
                    Email
                  </label>
                  <div className="uk-form-controls">
                    <input className="uk-input" id="email" type="email" placeholder="Votre email..." name="email" onChange={this.handleChange} required />
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="message">
                    Message
                  </label>
                  <div className="uk-form-controls">
                    <textarea
                      className="uk-textarea"
                      id="message"
                      rows="6"
                      placeholder="Votre message..."
                      name="message"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="recaptcha">
                    Anti-spam
                  </label>
                  <div className="uk-form-controls">
                    <Recaptcha ref="recaptcha" sitekey={process.env.GATSBY_RECAPTCHA_KEY} onChange={this.handleRecaptcha} />
                  </div>
                </div>
                <div className="uk-margin">
                  <div className="uk-form-controls">
                    {!this.state.isLoading && (
                      <button type="submit" className="uk-button uk-button-secondary" disabled={this.state.recaptcha ? false : true}>
                        Envoyer
                      </button>
                    )}
                    {this.state.isLoading && (
                      <Fragment>
                        <span uk-spinner="" className="uk-margin-small-left uk-margin-small-right" />
                        Envoi en cours...
                      </Fragment>
                    )}
                  </div>
                </div>
              </form>
            )}

            {this.state.submit === "success" && (
              <div className="uk-text-center">
                <div className="uk-alert-success" data-uk-alert>
                  <div className="uk-flex uk-flex-middle uk-flex-center">
                    <span className="uk-flex-none uk-margin-small-right" data-uk-icon="check" />
                    <strong>Votre message a bien été envoyé à contact@guslyon.fr</strong>
                  </div>
                </div>
                <h3>Message envoyé !</h3>
                <p>Une copie vous a été envoyée sur {this.state.email}</p>
                <p>
                  Merci, je vous répond au plus vite ;-)
                  <br />
                  <small>
                    --
                    <br />
                    Gus LYON
                  </small>
                </p>
                <p>&nbsp;</p>
                <p>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</p>
                <p>
                  <strong>VOTRE MESSAGE</strong>
                </p>
                <p dangerouslySetInnerHTML={{ __html: this.state.message }} />
              </div>
            )}
            {this.state.submit === "error" && (
              <div className="uk-text-center">
                <div className="uk-alert-danger" data-uk-alert>
                  <div className="uk-flex uk-flex-middle uk-flex-center">
                    <span className="uk-flex-none uk-margin-small-right" data-uk-icon="warning" />
                    <strong>Une erreur est survenue lors de l'envoi de votre message...</strong>
                  </div>
                </div>
                <h3>Message non envoyé...</h3>
                <p>Veuillez vérifier la saisie de votre adresse email ou envoyer un message directement depuis votre messagerie à contact@guslyon.fr</p>
              </div>
            )}
          </Section>
        </Layout>
      </div>
    )
  }
}
